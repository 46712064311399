import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./images.css"

export default function ClickyImages() {

    const navigate = useNavigate();

    const container1Ref = useRef(null);
    const container2Ref = useRef(null);

    const handleMouseEnter = (ref, notref) => {
        ref.current.querySelector(".mainImage").classList.add("hover");
        ref.current.classList.add("hover");
        notref.current.querySelector(".mainImage").classList.add("nothover");
        notref.current.classList.add("nothover");
    }
    
    const handleMouseLeave = (ref, notref) => {
        ref.current.querySelector(".mainImage").classList.remove("hover");
        ref.current.classList.remove("hover");
        notref.current.querySelector(".mainImage").classList.remove("nothover");
        notref.current.classList.remove("nothover");
    }

    const style = {
        imagesContainer: {
            width: "100vw",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer"
        },
    };

    return (
        <div style={style.imagesContainer}>
            <div onClick={() => navigate("/professional")} className="imageContainer" ref={container1Ref} 
                onMouseEnter={() => handleMouseEnter(container1Ref, container2Ref)}
                onMouseLeave={() => handleMouseLeave(container1Ref, container2Ref)}>
                <div className="textOverlay">
                    PROFESSIONAL
                </div>
                <div className="mainImage" style={{ backgroundImage: "url(images/main_left.png" }} />
            </div>
            <div onClick={() => navigate("/personal")} className="imageContainer" ref={container2Ref} 
                onMouseEnter={() => handleMouseEnter(container2Ref, container1Ref)}
                onMouseLeave={() => handleMouseLeave(container2Ref, container1Ref)} >
                <div className="textOverlay">
                    PERSONAL
                </div>
                <div className="mainImage" style={{ backgroundImage: "url(images/main_right.png" }} />
            </div>
            
        </div>
    );
}