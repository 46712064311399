import React from 'react';
import StaticHeader from "../components/StaticHeader";
import Banner from '../components/professional/banner';
import { useNavigate } from 'react-router-dom';
import Details from './Details';
import "../components/professional/anim.css"

const LeftImage = ({ navigate, url, imgURL, title }) => {
    return <div className="item" onClick={() => navigate(url)} style={{ 
        width: "50vw", 
        height: "50vh", 
        display: 'flex', 
        flexDirection: "row", 
        justifyContent: "center", 
        alignItems: "center", 
        padding: "5%", 
        cursor: "pointer" 
        }}>
        <img src={imgURL} style={{ 
            height: "90%", 
            width: "auto", 
            paddingRight: "5vh"
        }} />
        <div style={{ 
            display: "flex",
            width: "40%",
            height: "90%",
            justifyContent: "flex-start",
            alignItems: "end",
            fontSize: "2em",
            padding: "10px",
            paddingBottom: "0px",
            fontWeight: "bold",
            fontFamily: "Didot",
            letterSpacing: 0.8
        }}>
            {title}
        </div>
    </div>
}

const RightImage = ({ navigate, url, imgURL, title }) => {
    return <div className="item" onClick={() => navigate(url)} style={{ 
        width: "50vw", 
        height: "50vh", 
        display: 'flex', 
        flexDirection: "row", 
        justifyContent: "center", 
        alignItems: "center", 
        padding: "5%", 
        cursor: "pointer" 
        }}>
        <div style={{ 
            display: "flex",
            width: "40%",
            height: "90%",
            justifyContent: "flex-start",
            textAlign: "right",
            alignItems: "end",
            fontSize: "2em",
            padding: "10px",
            paddingBottom: "0px",
            fontWeight: "bold",
            fontFamily: "Didot",
            letterSpacing: 0.8
        }}>            
            {title}
        </div>
        <img src={imgURL} style={{ height: "50vh", width: "50vh", paddingLeft: "5vh" }} />
    </div>
}

export default function Professional({}) {

    const navigate = useNavigate();

    const handleNavigateBack = () => {
        navigate("/"); // Navigate to home
    };

    return <div>
        <div style={{position: "fixed", zIndex: 999}}>
            <StaticHeader />
            <Banner pageTitle="Professional" previousPage="Home" onNavigateBack={handleNavigateBack} />
        </div>
        <div style={{height: "25vh", width: "100vw", backgroundColor: "#FDEFED" }} />
        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#FDEFED"}}>
            {/* Left Image style */}
            <LeftImage navigate={navigate} url="./roksanda" imgURL="./images/professional_1.png" title="LONDON FASHION WEEK A/W 2023" />
            <RightImage navigate={navigate} url="./lcf" imgURL="./images/professional_2.png" title="LONDON COLLEGE OF FASHION" />
            <LeftImage navigate={navigate} url="./digital_engagement" imgURL="./images/professional_3.png" title="DIGITAL ENGAGEMENT" />
            <RightImage navigate={navigate} url="./retail" imgURL="./images/professional_4.png" title="'REET'AIL" />
            {/* Right Image Style */}
        </div>
        <div style={{ height: "20vh", width: "100vw", backgroundColor: "#FDEFED" }} />
    </div>
}