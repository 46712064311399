import logo from './logo.svg';
import './App.css';
import { Route, Routes } from "react-router-dom"
import Main from './pages/Main';
import Professional from './pages/Professional';
import Details from "./pages/Details"
import DetailMain from './components/details/DetailMain';
import DetailLeft from './components/details/DetailsLeft';
import DetailRight from './components/details/DetailsRight';
import ComingSoon from './components/personal/ComingSoon';

const data = {
  roksanda: {
    title: "ROKSANDA",
    headContent: "I was a part of the PR & Marketing team at Roksanda in 2023 for the Autumn/ Winter 2023 Runway Show. I played a pivotal role in managing daily send-outs of fashion looks to a diverse range of VIP celebrities, influencers, and magazines, utilizing tools such as Excel and SampleManager (Launchmetrics).",
    headImages: ["/images/roksanda/top_1.png", "/images/roksanda/top_2.png"],
    sections: [
      {
        title: "Key Highlights & Skills:",
        content: "Event Coordination: I played a crucial role in preparing and executing Roksanda's London Fashion Week show for AW23. I managed the attendance of over 30 VIP guests and sponsors, ensuring a seamless and memorable event experience. \
\n\nVIP Relations: I handled daily send-outs of SS23/AW23 looks to over 40 VIP celebrities, influencers, and magazines. Using Excel and SampleManager, I streamlined coordination, significantly improving our operations' efficiency. \
\n\nMedia Relations: I fostered strong relationships with key media contacts, coordinated interviews, photo shoots, and media requests, ensuring Roksanda's narrative was consistently and effectively communicated.",
        images: ["/images/roksanda/gallery_0.png", "/images/roksanda/gallery_1.png", "/images/roksanda/gallery_2.png", "/images/roksanda/gallery_3.png"]
      },
      {
        title: "",
        content: "Analytical Reporting: I compiled digital press reviews, I supported reporting and analysis, producing weekly reviews to aid in strategic planning for future campaigns. \
\n\nTeam Collaboration: I worked closely with the PR & Celebrity team, contributing to brainstorming sessions and providing additional support for specific press and celebrity events. This collaboration enhanced my ability to work effectively in a high-performance team environment. \n \
\n\nThrough this role, I developed a comprehensive skill set that includes advanced organisational abilities, strategic communication, and a deep understanding of the luxury fashion industry. My experience at Roksanda has not only enhanced my professional capabilities but also fuelled my passion for fashion marketing and PR.",
        images: ["/images/roksanda/gallery_1_0.png", "/images/roksanda/gallery_1_1.png", "/images/roksanda/gallery_1_2.png", "/images/roksanda/gallery_1_3.png"]
      }
    ]
  },
  lcf: {
    title: "BA (HONS) FASHION MARKETING",
    headContent: "My journey at the London College of Fashion was transformative, equipping me with a robust foundation in fashion marketing and an array of practical skills essential for the industry.",
    headImages: ["/images/ual/top_0.png", "/images/ual/top_1.png", "/images/ual/top_2.png", "/images/ual/top_3.png"],
    sections: [
      {
        title: "Dissertation",
        content: "In my dissertation, I explored the psychology of colour and its influence on luxury brand identity, focusing on iconic brands like Louboutin, Bottega Veneta, and Tiffany & Co. Through this research, I honed my ability to conduct and analyse focus groups, particularly in understanding Gen-Z consumer behaviour, and carried out comprehensive literature reviews that bridged theory with practical application.\
\n\nThis project also sharpened my analytical abilities, allowing me to draw significant conclusions about the strategic use of colour in branding, and highlighted the importance of creativity and adaptability in maintaining brand relevance in a dynamic market.",
        images: ["/images/ual/diss_0.png", "/images/ual/diss_1.png", "/images/ual/diss_2.png", "/images/ual/diss_3.png", "/images/ual/diss_4.png"],
        link: "/files/dissertation.pdf"
      },
      {
        title: "Global Fashion Branding",
        content: "During my studies in Global Fashion Branding, I gained a deep understanding of brand building, identity and management within the fashion industry.I composed an extensive brand situational analysis of the Georgian brand ANOUKI and crafted integrated marketing communications plans for ANOUKI's market entry into the UK.\
\n\nMy other coursework involved conducting a brand audits on Gucci, which enriched my knowledge of brand identity, image, and personality. I delved into strategies such as brand extension, co-branding, and multi-channel retail marketing. Overall, I honed my skills in developing effective marketing campaigns and PR strategies, utilizing customer insights, and ensuring ethical brand communications on a global scale.",
        images: ["/images/ual/gfb_0.png", "/images/ual/gfb_1.png", "/images/ual/gfb_2.png", "/images/ual/gfb_3.png"],
        link: "/files/anouki.pdf"
      },
      {
        title: "Consumer Insights",
        content: "In the Consumer Insights module, I honed my skills in applying consumer behaviour theories to real-world scenarios, focusing especially on 'Fashionistas on a Budget' in the UK and India. I gained a strong understanding of psychographics, demographics, and shopper behaviour, which I applied to analyse the fashion choices and purchasing habits of Gen Z consumers.\
\n\nThis module enhanced my skills in both primary and secondary research, from conducting interviews and wardrobe audits to analysing complex data for actionable insights. I also became skilled in cross-cultural analysis and understanding the role of social media and online shopping in shaping consumer behaviour, which has prepared me to offer targeted recommendations for global fashion markets.",
        images: ["/images/ual/ci_0.png", "/images/ual/ci_1.png", "/images/ual/ci_2.png", "/images/ual/ci_3.png"],
        link: "/files/consumer-insights.pdf"
      },
      {
        title: "Fashion & Innovation: Hyperphysical Spaces",
        content: "In the Fashion & Innovation module, I explored how brands can build consumer trust through transparency and personalised experiences. My report focused on the impact of haptic technology in enhancing user interactions by appealing to the sense of touch, leading to more immersive and satisfying retail experiences. I investigated how incorporating tactile elements into fashion retail not only addresses consumers' physical needs but also sets new benchmarks for innovation.\
\n\nThis unit provided me with a deeper understanding of the dynamic issues shaping the fashion industry and marketing, including technological advancements, societal shifts, and sustainability. Engaging with trend forecasting and scenario planning helped me develop a comprehensive view of how these factors influence future trends in fashion and retail marketing.",
        images: ["/images/ual/fni_0.png", "/images/ual/fni_1.png", "/images/ual/fni_2.png", "/images/ual/fni_3.png"],
        link: "/files/hyperphysical-stores.pdf"
      },
      {
        title: "Fashion Marketing Strategy",
        content: "During my Fashion Marketing Strategy module, I explored the complexities of corporate strategy and business models, developing my skills in fashion branding and marketing. Working on case studies such as Ganni and creating an in-depth mood board for Balenciaga gave me practical insights into strategic environmental analysis, market expansion, and international development.\
\n\nThese hands-on projects allowed me to identify and apply those concepts and frameworks, enabling me to craft customised solutions and recommendations for fashion brands based on extensive research and current consumer trends. This module deepened my understanding of strategic positioning and differentiation, including sustainability initiatives, and enhanced my ability to make informed, strategic marketing decisions.",
        images: ["/images/ual/fms_0.png", "/images/ual/fms_1.png", "/images/ual/fms_2.png", "/images/ual/fms_3.png"],
        link: "/files/moodboard.png"
      }
    ]
  },
  digital_engagement: {
    title: "DIGITAL ENGAGEMENT",
    headContent: "In my digital engagement roles, I have trained AI models at DataAnnotation, managed marketing campaigns at Kosha Capital LLC, and led content strategy at Guaranti International Limited. These experiences have sharpened my expertise in AI, digital marketing, content creation",
    headImages: ["/images/digital_engagement/top_0.png", "/images/digital_engagement/top_1.png"],
    sections: [
      {
        title: "DATA ANNOTATION",
        content: "AI Data Trainer\n\nAt DataAnnotation, I contribute to advancing AI technology by training generative AI models. My role involves providing human feedback on AI responses, pinpointing inaccuracies, and ensuring the information is correct. I work across a wide range of subjects, including general knowledge, mathematics, history, and politics.\
\n\nMy work not only improves the reliability and quality of AI responses but also sharpens my analytical skills, attention to detail, and expertise in AI and machine learning technologies.\
\n\nThis role has inspired me to research generative AI and machine learning technologies, and their uses or potential uses in the fashion industry.",
        images: ["/images/digital_engagement/da_0.png", "/images/digital_engagement/da_1.png", "/images/digital_engagement/da_2.png", "/images/digital_engagement/da_3.png"]
      },
      {
        title: "KOSHA CAPITAL LLC",
        content: "Marketing Campaign Management Intern\
\n\nAt Kosha Capital LLC, I played an essential role in expanding the company's digital presence. My primary responsibilities included creating engaging written content for web platforms, which significantly increased user engagement and strengthened Kosha's brand identity.\
\n\nI collaborated closely with visual web designers to ensure that all digital content was both visually appealing and consistent with the company's brand ethos.\
\n\nAdditionally, I facilitated effective communication with B2B clients, ensuring our digital campaigns resonated with our target audience and achieved the desired results. This experience enhanced my skills in content strategy, SEO, and digital marketing analytics.",
        images: ["/images/digital_engagement/kc_0.png", "/images/digital_engagement/kc_1.png", "/images/digital_engagement/kc_2.png", "/images/digital_engagement/kc_3.png"]
      },
      {
        title: "GUARANTI INTERNATIONAL LIMITED",
        content: "Marketing & Branding Campaign Management Intern\
\n\nAt Guaranti International Limited, I led the development and maintenance of written content for the company's website and social media channels, which was crucial in fostering a cohesive online brand presence.\
\n\nI collaborated with the web designer to create a website that effectively communicated with clients and reflected Guaranti's brand values. My role also involved analySing digital engagement metrics to continuously refine our content strategy.\
\n\nThrough this internship, I developed strong skills in social media management, content creation, and digital strategy implementation, significantly improving the company's online visibility and client engagement.",
        images: ["/images/digital_engagement/gi_0.png", "/images/digital_engagement/gi_1.png", "/images/digital_engagement/gi_2.png", "/images/digital_engagement/gi_3.png"]
      }
    ]
  },
  retail: {
    title: "'REET'AIL",
    headContent: "During my internships at Tommy Hilfiger and ICONIC, I gained hands-on experience in retail operations, inventory management, and visual merchandising. I also honed my customer service skills and learned to think strategically. These roles gave me valuable insights into retail strategies and a deeper understanding of consumer behavior.",
    headImages: ["/images/reetail/top_0.png", "/images/reetail/top_1.png"],
    sections: [
      {
        title: "TOMMY HILFIGER",
        content: "General Sales Intern\n\nDuring my time at Tommy Hilfiger, I assisted the General Manager for the MENA region in keeping operational standards consistent across 13 stores in Dubai.\
\n\nI helped manage inventory levels, ensuring stock met customer demand, and coordinated logistical operations to run smoothly. I also worked closely with the customer service team to develop and implement strategies that improved the shopping experience and boosted brand loyalty.\
\n\nThis internship enhanced my skills in retail operations, customer service, and inventory management, while offering valuable insights into effective retail strategies and teamwork.",
        images: ["/images/reetail/tommy_0.png", "/images/reetail/tommy_1.png", "/images/reetail/tommy_2.png", "/images/reetail/tommy_3.png"]
      },
      {
        title: "ICONIC, LANDMARK",
        content: "Visual Merchandising Intern\
\n\nAt Landmark Group's Iconic stores, I was responsible for the meticulous setup of  the new autumn/winter collection across multiple locations.\
\n\nI made sure that the visual displays were on-brand and appealed to our target customers. I also compiled detailed reports for over 10 stores, analysing the effectiveness of our visual merchandising and making recommendations for improvements.\
\n\nThis role developed my skills in visual presentation, trend analysis, and strategic planning, and gave me a deeper understanding of consumer behavior and store aesthetics.",
        images: ["/images/reetail/iconic_0.png", "/images/reetail/iconic_1.png", "/images/reetail/iconic_2.png", "/images/reetail/iconic_3.png"]
      }
    ]
  }
}

const Roksanda = ({ }) => {
  const title = "roksanda"
  return <Details title={data[title].title} mainDetails={<DetailMain title={data[title].title} text={data[title].headContent} images={data[title].headImages} />} extraContent={data[title].sections.map((item, index) => {
    console.log(item)
      if (index % 2 == 0) {
        return <DetailRight title={item.title} text={item.content} images={item.images} />
      } else {
        return <DetailLeft title={item.title} text={item.content} images={item.images} />
      }
  })} />
}
const LCF = ({}) => {
  const title = "lcf"
  return <Details title={data[title].title} mainDetails={<DetailMain title={data[title].title} text={data[title].headContent} images={data[title].headImages} />} extraContent={data[title].sections.map((item, index) => {
    if (index % 2 == 0) {
      if (item.link) {
        return <a href={item.link} target="_blank" style={{ textDecoration: "none", color: "black"}}>
          <DetailRight title={item.title} text={item.content} images={item.images} />
        </a>
      } else {
        return <DetailRight title={item.title} text={item.content} images={item.images} />
      }
    } else {
      if (item.link) {
        return <a href={item.link} target="_blank" style={{ textDecoration: "none", color: "black"}}>
          <DetailLeft title={item.title} text={item.content} images={item.images} />
        </a>
      } else {
        return <DetailLeft title={item.title} text={item.content} images={item.images} />
      }
    }
  })} />
}
const DigitalEngagement = ({}) => {
  const title = "digital_engagement"
  return <Details title={data[title].title} mainDetails={<DetailMain title={data[title].title} text={data[title].headContent} images={data[title].headImages} />} extraContent={data[title].sections.map((item, index) => {
    if (index % 2 == 0) {
      return <DetailRight title={item.title} text={item.content} images={item.images} />
    } else {
      return <DetailLeft title={item.title} text={item.content} images={item.images} />
    }
  })} />
}
const Retail = ({}) => {
  const title = "retail"
  return <Details title={data[title].title} mainDetails={<DetailMain title={data[title].title} text={data[title].headContent} images={data[title].headImages} />} extraContent={data[title].sections.map((item, index) => {
    if (index % 2 == 0) {
      return <DetailRight title={item.title} text={item.content} images={item.images} />
    } else {
      return <DetailLeft title={item.title} text={item.content} images={item.images} />
    }
  })} />
}

function App() {

  

  return (
    <div className="App-header">

      <Routes>
        <Route exact path="/" Component={Main}/>
        <Route path="/personal" Component={ComingSoon} />
        <Route path="/professional" Component={Professional} />
        <Route path="/professional/roksanda" Component={Roksanda} />
        <Route path="/professional/lcf" Component={LCF} />
        <Route path="/professional/digital_engagement" Component={DigitalEngagement} />
        <Route path="/professional/retail" Component={Retail} />

      </Routes>
    </div>
  );
}

export default App;
