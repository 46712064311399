import React from "react"
import ImageGallery from "./ImageGallery";

const DetailLeft = ({ title, text, images }) => {
    return (
      <div style={styles.container}>
        <div style={styles.left}>
            <div style={styles.header}>
                {title}
            </div>
            <div style={styles.mainContent}>
                {text}
            </div>
        </div>
        <div style={styles.right}>
          <div style={styles.imageContainer}>
            <ImageGallery images={images} />
          </div>
        </div>
      </div>
    );
  };
  
  const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        padding: 20,
        letterSpacing: 1
    },
    left: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: '50%',
        padding: 30,
        paddingTop: 0,

        position: "relative"
    },
    header: {
        fontSize: "1em",
        padding: 20,
        paddingLeft: 50,
        paddingTop: 0
    },
    mainContent: {
        fontSize: "0.6em",
        padding: 40,
        paddingLeft: 50,
        paddingRight: 20,
        whiteSpace: "pre-wrap",
        borderTop: "1px solid black",
        borderLeft: "1px solid black",
        textAlign: "justify"
        
    },
    right: {
        width: "50%",
        position: "relative",
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center'

    },
    imageContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
        width: "100%"
    },
  };
  
  export default DetailLeft;