import React from "react"
import ImageGallery from "./ImageGallery";

const DetailMain = ({ title, text, images }) => {
    return (
      <div style={styles.container}>
        <div style={styles.left}>
            <div style={styles.header}>
                {title}
            </div>
            <div style={styles.mainContent}>
                {text}
            </div>
        </div>
        <div style={styles.right}>
          <div style={styles.imageContainer}>
            <ImageGallery images={images} />
          </div>
        </div>
      </div>
    );
  };
  
  const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        padding: 50,
        whiteSpace: "pre-wrap",
        letterSpacing: 1,
    },
    left: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        borderTop: "1px solid black",
        borderLeft: "1px solid black",
        borderBottom: "1px solid black",
        width: '50%',
        padding: 30,
        paddingLeft: 60
    },
    header: {
        fontSize: "1.5em",
    },
    mainContent: {
        fontSize: "0.6em",
        textAlign: "justify"
    },
    right: {
        borderTop: "1px solid black",
        width: "50%",

    },
    imageContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
        width: "100%"
    },
    image: {
    }
  };
  
  export default DetailMain;