import React, { useEffect, useState } from "react";
import { FaLinkedin } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoMdCalendar } from "react-icons/io";
import { FaRegFile } from "react-icons/fa";

function Icon({icon, bigtext, smalltext, link, download = false}) {
    return <a href={link} target="_blank" download={download} style={{ 
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        color: "#333", 
        textAlign:"left",
        letterSpacing: "1px",
        padding: 30,
    }} onClick={onclick}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: 20 }}>
            { icon }
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", padding: 10 }}>
            <div style={{ width: "100%", fontSize: "0.8em", padding: 5}}>
                { bigtext }
            </div>
            <div style={{ width: "100%", fontSize: "0.6em", padding: 5}}>
                { smalltext }
            </div>
        </div>
    </a>
}

export default function GetInTouch({ closeModal }) {

  const linkedinLink = "https://www.linkedin.com/in/reet-rachwani"
  const emailText = "reet.rachwani2003@gmail.com"
  const meetingText = "https://www.calendly.com/reetrachwani"

  const [blurLevel, setBlurLevel] = useState("0px");
  const [modalTranslate, setModalTranslate] = useState(100);
  const [isClosing, setIsClosing] = useState(false);
  
  useEffect(() => {
    const duration = 2000; // 2 seconds
    const intervalTime = 10; // interval time in ms
    const totalSteps = duration / intervalTime;

    let stepCount = 0;

    setBlurLevel("7px");
    setModalTranslate(0);

  }, []);

  useEffect(() => {
    // If the component is in the process of closing, reverse the animation
    if (isClosing) {
      setBlurLevel("0px");
      setModalTranslate(100);

      // Wait for the animation to finish before calling closeModal
      const timer = setTimeout(() => {
        closeModal();
      }, 1000); // Match this duration with your CSS transition duration

      return () => clearTimeout(timer);
    }
  }, [isClosing, closeModal]);

  const handleClose = () => {
    setIsClosing(true);
  };
  
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100vw",
        height: "100vh",
        // background: "rgba(0, 0, 0, 0.5)", // Optional, adds a dimming effect
        zIndex: 9999,
      }}
    >
      {/* Background blur layer */}
      <div
        onClick={handleClose}
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          backdropFilter: `blur(${blurLevel})`,
          transition: "backdrop-filter 1s", // Smooth transition for blur
        }}
      />
      
      {/* Modal */}
      <div
        style={{
          position: "fixed",
          right: 0,
          top: 0,
          width: "40%",
          height: "100%",
          backgroundColor: "white",
          boxShadow: "-2px -2px 5px rgb(0, 0, 0, 0.3)",
          transform: `translateX(${modalTranslate}%)`,
          transition: "transform 1s", // Smooth transition for slide-in
          padding: 30,

        }}
      >
        {/* Add your modal content here */}
        <div style={styles.header}>
            GET IN TOUCH
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%", padding: 30, marginBottom: 30 }}>
            <Icon  icon={<IoMdMail size={"1.5em"} />} bigtext="Email me" smalltext={emailText} link={"mailto:" + emailText} />
            <Icon  icon={<FaLinkedin size={"1.5em"} />} bigtext="Let's Connect" smalltext={linkedinLink} link={linkedinLink} />
            <Icon  icon={<IoMdCalendar size={"1.5em"} />} bigtext="Book a meeting" smalltext={meetingText} link={meetingText} />
            <Icon  icon={<FaRegFile size={"1.5em"} />} bigtext="Download my CV" smalltext="" link={"/files/cv.pdf"} download />
        </div>
      </div>
    </div>
  );
}

const styles = {
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
        padding: 30
    }
}