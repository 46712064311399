import { useEffect, useState } from "react";
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import GetInTouch from "./GetInTouch";
import "./headerStyles.css"

const calculateTitleHeight = (scrollPosition, windowDimensions) => {
    const heightVal = (windowDimensions.height / 5) - (1 * scrollPosition);
    if (windowDimensions.width < 1000) {
        return (windowDimensions.height / 5)
    } 
    return heightVal < 0 ? 0 : heightVal;
}

const calculateTitleFontSize = (scrollPosition, windowDimensions) => {
    // if scrollPosition = 0, size=200
    // as scrollPosition increases, size -= 1
    const titleFontSize = (windowDimensions.width / 10) - scrollPosition;
    if (scrollPosition < 0) {
        return windowDimensions.width / 10;
    }
    if (titleFontSize < windowDimensions.width / 30) {
        return windowDimensions.width / 30;
    } else {
        return titleFontSize;
    }
}

const calculateIconSize = (windowDimensions) => {
    return windowDimensions.width < 800 ? "small" : "medium"
}

export default function Header({ }) {

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }
    
    const [titleHeight, setTitleHeight] = useState(200);
    const [titleFontSize, setTitleFontSize] = useState(28);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [bigTitleShow, setBigTitleShow] = useState(true);
    const [iconSize, setIconSize] = useState("small");
    const [showGetInTouch, setShowGetInTouch] = useState(false);
    
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const handleGetInTouch = () => {
        console.log("SHOULD BE BLURRY")
        setShowGetInTouch(prev => !prev)
    }
    
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(() => getWindowDimensions());
          setTitleHeight(calculateTitleHeight(scrollPosition, windowDimensions));
          setTitleFontSize(calculateTitleFontSize(scrollPosition, windowDimensions));
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    
    useEffect(() => {
        function handleResize() {
        setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    useEffect(() => {
        setTitleHeight(calculateTitleHeight(scrollPosition, windowDimensions));
        setTitleFontSize(calculateTitleFontSize(scrollPosition, windowDimensions));
        setIconSize((prev) => {
            return windowDimensions.width < 800 ? "small" : "large";
        });
        console.log(titleHeight)

    }, [scrollPosition, windowDimensions]);

    useEffect(() => {
        calculateTitleHeight(scrollPosition, windowDimensions) === 0 ? setBigTitleShow(false) : setBigTitleShow(true);
    }, [scrollPosition])

    
    return <div style={style.headerContainer}>
        
        {/* contact page overlay */}
        {showGetInTouch && <GetInTouch closeModal={() => setShowGetInTouch(false)} />}

        {/* Top Section */}
        <div style={style.topHeaderContainer}>
            {/* Left Section */}
            {/* Right section */}
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                {/* <div style={style.icon}>
                    <Person2OutlinedIcon fontSize={iconSize} />
                    </div>
                    <div style={style.icon}>
                    <SearchOutlinedIcon fontSize={iconSize} />
                    </div>
                    <div style={style.icon}>
                    <MenuOutlinedIcon fontSize={iconSize} />
                </div> */}
            </div>
            <div className="rightSection" style={{ zIndex: 1, cursor: "pointer", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "0.8em" }} onClick={() => handleGetInTouch()}>
                <div>
                    GET IN TOUCH
                </div>
                <div style={style.icon}>
                    <MenuOutlinedIcon fontSize={iconSize} />
                </div>
            </div>
        </div>
        {/* Main Section */}
        {/* Needs to get Scroll position and have height change up to a certain number. This can be a function. */}
        {<div style={{ width: "100vw", top: "6vh", height: titleHeight, position: 'fixed', display: "flex", alignItems: "center", justifyContent: "center", fontSize: titleFontSize, fontFamily: "bentham" }} >
            {/* Title */}
            reet all about it
        </div>}
    </div>
}

const style = {
    headerContainer: {
        background: "white",
        width: "100vw",
        top: 0,
        position: "fixed",
        display: "flex",
        justifyContent: "space-between",
        left: 0,
        zIndex: 9999,
        flex: 1,
    },
    topHeaderContainer: {
        display: "flex",
        width: "100vw",
        height: "9vh",
        padding: 20,
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "calc(10px + 1vw)",
        
    },
    icon: {
        cursor: "pointer",
        margin: 15,
        paddingTop: 4
    }
}