import { useEffect, useState } from "react";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import GetInTouch from "./GetInTouch";
import "./headerStyles.css"

export default function Header({ }) {

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [showGetInTouch, setShowGetInTouch] = useState(false);

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(() => getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    
      return (
        <div style={style.headerContainer}>
            {/* GET IN TOUCH FUNCTION */}
            {showGetInTouch && <GetInTouch closeModal={() => setShowGetInTouch(false)} />}
            
            {/* Left Section - GET IN TOUCH */}
            <div style={style.leftSection} onClick={() => setShowGetInTouch(true)}>
            </div>
            
            {/* Title - reet all about it */}
            <a href="/" style={style.title}>
                reet all about it
            </a>
            
            {/* Right section - icons or empty */}
            <div className={"rightSection"} style={style.rightSection}  onClick={() => setShowGetInTouch(true)}>
                {/* You can add icons here if needed */}
                <div >
                    GET IN TOUCH
                </div>
                <div style={style.icon}>
                    <MenuOutlinedIcon fontSize={"large"} />
                </div>
            </div>
        </div>
    );
}

const style = {
    headerContainer: {
        background: "white",
        width: "100vw",
        height: "9vh",
        top: 0,
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "calc(10px + 1vw)",
        padding: 20,
        paddingLeft: "10px", // Padding to the left of the entire header
        left: 0,
        zIndex: 9999,
    },
    leftSection: {
        paddingLeft: "20px", // Padding to the left of "GET IN TOUCH"
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "33%",
        height: "100%",
    },
    title: {
        textDecoration: "none",
        color: "#333",
        fontFamily: "bentham",
        fontSize: "calc(10vw / 3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        flex: 1, // Take up remaining space and center align
    },
    rightSection: {
        width: "33%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        height: "100%",
        paddingRight: "45px",
        fontSize: "0.8em",
        cursor: "pointer",


    },
    icon: {
        paddingTop: 4,
        paddingLeft: 10
    }
};