import StaticHeader from "../components/StaticHeader";
import Footer from "../components/footer";
import Banner from "../components/professional/banner";
import "./Details.css"
import { useNavigate } from 'react-router-dom';

export default function Details({ title, mainDetails, extraContent }) {

    const navigate = useNavigate();

    return <div style={{ backgroundColor: "#FDEFED", backgroundRepeat: "repeat"}}>
        <div style={{position: "fixed", zIndex: 1000, width: "100vw"}}>
            <StaticHeader />
            <Banner pageTitle={title} previousPage="Professional" onNavigateBack={() => navigate("/professional")} />
        </div>
        <div style={{width: "100vw", backgroundColor: "#FDEFED"}}>
            <div className="container">
                <div style={{ zIndex: 0, display: "flex"}}>
                    {mainDetails}
                </div>

                {extraContent}
            </div>
            {/* <Footer /> */}
        </div>
    </div>
}

