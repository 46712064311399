import React from "react"

import StaticHeader from "../StaticHeader";
import Banner from "../professional/banner";
import { useNavigate } from 'react-router-dom';

export default function ComingSoon({ }) {

    const navigate = useNavigate();

    return <div style={{ width: "100%"}}>
        <div style={{position: "fixed", zIndex: 1000, display: "flex", flexDirection: "column"}}>
            <StaticHeader />
            <Banner pageTitle={"PERSONAL"} previousPage="Home" onNavigateBack={() => navigate("/")} />
            <div style={{ position: "fixed", top: "17vh", width: "100vw", height: "100vh", overflow: "scroll", backgroundImage: "url(images/personal/coming_soon.png", backgroundSize: "cover", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    LAUNCHING SOON
            </div>
        </div>
    </div>
}

