import React from 'react';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import "./banner.css"

export default function Banner({ pageTitle, previousPage, onNavigateBack }) {
    return (
        <div style={style.bannerContainer} onClick={onNavigateBack}>
            <div className="leftContainer" style={style.leftContainer}>
                <div style={style.arrowContainer}>
                    <ArrowBackIosOutlinedIcon fontSize="small" style={style.arrowIcon} />
                </div>
                <div style={style.previousPage}>
                    {previousPage.toUpperCase()}
                </div>
            </div>
            <div style={style.pageTitle}>
                {pageTitle.toUpperCase()}
            </div>
        </div>
    );
}

const style = {
    bannerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: "fixed",
        top: "calc(9vh + 40px)",
        width: '100vw',
        height: '8vh',
        backgroundColor: 'black',
        color: "white",
        paddingLeft: '20px',
        cursor: 'pointer',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
        zIndex: 1000
    },
    leftContainer: {
        display: "flex",
        flexDirection: "row",

    },
    arrowContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '10px',
    },
    arrowIcon: {
        marginRight: '5px',
    },
    pageTitle: {
        fontSize: '1.1em',
        color: 'white',
        fontFamily: 'centurygothic',
        padding: 30
    },
    previousPage: {
        fontSize: "0.8em",
        color: "white",
        fontFamily: "centurygothic",
    }
};