import React from "react"
import ImageGallery from "./ImageGallery";

const DetailRight = ({ title, text, images }) => {
    return (
      <div style={styles.container}>
        <div style={styles.left}>
          <div style={styles.imageContainer}>
            <ImageGallery images={images} />
          </div>
        </div>
        <div style={styles.right}>
            <div style={styles.header}>
                {title}
            </div>
            <div style={styles.mainContent}>
                {text}
            </div>
        </div>
      </div>
    );
  };
  
  const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        padding: 20,
        letterSpacing: 1
        
    },
    left: {
        width: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    header: {
        fontSize: "1em",
        padding: 20,
        paddingTop: 0,
        border: "none"
    },
    
    right: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: '50%',
        padding: 30,
        paddingTop: 0
    },
    imageContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
        width: "100%"
    },
    mainContent: {
        borderTop: "1px solid black",
        borderRight: "1px solid black",
        fontSize: "0.6em",
        padding: 40,
        paddingRight: 70,
        paddingLeft: 20,
        whiteSpace: "pre-wrap",
        textAlign: "justify"
    }
  };
  
  export default DetailRight;