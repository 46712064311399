import { useEffect, useState } from "react";
import AboutMe from "./AboutMe";
import ClickyImages from "./ClickyImages";
import Footer from "./footer";




export default function Body() {

    const [showFooter, setShowFooter] = useState(window.innerWidth > 1500);

    useEffect(() => {
        const handleResize = () => {
        setShowFooter(window.innerWidth > 1500);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div style={{marginTop: "30vh", backgroundColor: "#FDEFED"}}>
            <ClickyImages src1={'url(images/main_left.png")'} src2={'url(images/main_right.png")'} />
            <div style={{width: "100vw", display: "flex", flexDirection: "column" }}>
                <div style={{ padding: 30, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", flex: 1 }}>
                    <div style={{ maxWidth: "1200px", minWidth: "1050px", width: "60%", borderTop: "1px solid black", borderBottom: "1px solid black", padding: 20, letterSpacing: 1}}>
                        ABOUT ME
                    </div>
                    <div style={{ letterSpacing: 1, justifyContent: "center", alignItems: "center"}}>
                        <div style={{ maxWidth: "1000px", textAlign: "justify", fontSize: "0.6em", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "calc(100% - 60px)"}}>
                            <div style={{ whiteSpace: "pre-wrap", width: "60%", padding: 30}}>
                                <p>Hi! I'm Reet Rachwani, a passionate PR and marketing professional based in London, with a Bachelor's degree in Fashion Marketing from the prestigious London College of Fashion.</p>
                                <p>My diverse background, includes roles in PR & marketing, AI data training,  and visual merchandising, which has equipped me with a unique blend of creative and analytical skills.</p>
                                <p>My journey in the fashion industry has been enriched by hands-on experience in managing high-profile fashion events, collaborating with VIPs, and crafting successful PR campaigns.</p>
                                <p>I thrive on creating compelling content, building strong media relations, and leveraging digital communications to enhance brand visibility.  I am dedicated to bringing innovative ideas to life and contributing to high-performance teams.</p>
                                <p>Follow along and reet all about it!</p>
                            </div>
                            <div style={{ width: "25%", display: "flex", flexDirection: "row", justifyContent: "flex-end", padding: "5%" }}>
                                <img style={{ borderRadius: "5px", objectFit: "contain", width: "100%", height: "auto", filter: "saturate(0.7)"}} src={"images/main_aboutme.png"}  />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <AboutMe title={"REET'S FEED"} content={ */}
                {/* <div style={{padding: 20, fontSize: 20}}>
                    [awaiting content]
                </div>} /> */}
                {/* {showFooter && <Footer />} */}
            </div>
        </div>
    );
}