import React, { useState, useEffect } from 'react';
import './ImageGallery.css'; // Import the CSS file for styling

const ImageGallery = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to go to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Function to go to the previous image
  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  // Set up an interval to automatically change images every 5 seconds
  useEffect(() => {
    const interval = setInterval(nextImage, 3000); // 5000 milliseconds = 5 seconds
    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [images.length]);

  return (
    <div className="gallery">
      {/* <button className="nav-button prev" onClick={prevImage}>❮</button> */}
      <div className="gallery-content">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="Gallery"
            className={`gallery-image ${index === currentIndex ? 'visible' : 'hidden'}`}
          />
        ))}
      </div>
      {/* <button className="nav-button next" onClick={nextImage}>❯</button> */}
    </div>
  );
};

export default ImageGallery;