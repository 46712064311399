import React from 'react'
import Header from '../components/Header'
import Body from '../components/Body'


export default function Main() {
    return <div>
        <Header />
        <Body />
    </div>
}